import React, { FunctionComponent } from 'react';
import { Select } from 'View/Common';
import { _mockData } from './_mock';

export interface IHealthInsuranceEntry {
  name: string;
  reductionRate: number;
  cooperationUrl: null|string;
  cooperationPartner: boolean;
}

interface IProps {
  onChange?: (healthInsuranceEntry: IHealthInsuranceEntry) => void;
  className?: string;
  style?: React.CSSProperties;
}

export const HealthInsuranceSelect: FunctionComponent<IProps> = ({ className, style, onChange }) => {

  const handleOnChange = (value: string) => {
    onChange && onChange(_mockData[parseInt(value)]);
  };

  return (
    <Select
      style={style}
      className={className}
      onChange={handleOnChange}
      options={_mockData.map((entry, index) => ({ value: index, label: entry.name }))}
      placeholder={'Wähle deine Krankenkasse'}
    />
  );
};


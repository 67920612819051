import React, { FunctionComponent } from 'react';
import Style from './Button.style';
import { ButtonProps } from 'antd';
import { Link } from '@reach/router';

type TProps = ButtonProps & {
  to?: string;
};
export const Button: FunctionComponent<TProps> = ({ children, to, ...props }) => {

  const ButtonComponent: FunctionComponent<ButtonProps> = () => (
    <Style {...props}>
      { children }
    </Style>
  );

  if(to && props.type === 'link') {
    return (
      <Link to={to} title={`Gehe zur Seite: ${to}`}>
        <ButtonComponent />
      </Link>
    );
  }
  return (
    <ButtonComponent />
  );

};

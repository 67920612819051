export enum ROUTES {
  ACTIVATION_LINK = "/purchase/connect/:token",
  ALREADY_PAYING = "/already_paying",
  BILLING_DETAILS = "/billing_details",
  CATEGORIES_FALLBACK = "/categories/*",
  CONFIRMATION = "/confirmation",
  CONFIRMATION_UNKNOWN = "/confirmation/unknown",
  CONTACT = "/contact",
  GOTHAER = "/krankenkasse/gothaer",
  GROUPS_FALLBACK = "/groups/*",
  HOME = "/",
  IMPRINT = "/imprint",
  LOGIN_CONFIRMATION = "/login/:token",
  PAYMENT_ERROR = "/payment_error",
  PAYMENT_METHOD = "/payment_method",
  PRICING = "/pricing",
  PRIVACY = "/privacy",
  RESEND_TOKEN = "/resend_token/",
  RESEND_TOKEN_B2B = "/resend_token/b2b",
  SLEEP_FALLBACK = "/open/sleep/*",
  SUMMARY = "/summary",
  TERMINATE = "/terminate",
  TERMINATE_SUCCESS = "/terminate/success",
  TERMS = "/terms",
  VOUCHER_LANDINGPAGE = "/voucher",
  VOUCHER_SUCCESS = "/voucher_success",
  XING_PREMIUM = "/xing-premium",
  XING_PRO_JOBS = "/xing-projobs",
  ZPP_LANDING_PAGE = "/krankenkasse"
}

import styled from 'styled-components';
import logo from 'img/7schlaefer-logo.svg';
import { asRem } from 'utils/asRem';
import { breakpoints, mediaQueries } from 'utils/Constants/Breakpoints';
import { colors } from 'utils/colors';


export default styled.header`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(31, 48, 72, 0.9);
  padding-left: ${asRem(16)};
  padding-right: ${asRem(16)};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  
  .wrapper {
    display: flex;
    align-items: center;
    flex: 1;
    max-width: ${asRem(1088)};
    width: 100%;
    padding: 0 ${asRem(20)};

    ${mediaQueries.md} {
      padding: 0;
    }
    
    .link-logo {
      flex: 1;
      display: flex;
      max-width: ${asRem(320)};
    }
    
    .image-logo {
      flex: 1;
      height: ${asRem(61)};
      background-image: url(${logo});
      background-repeat: no-repeat;

      ${mediaQueries.md} {
        height: ${asRem(72)};
        background-position-x: ${asRem(-20)};
      }
    }

    nav.menu {
      display: none;
      color: ${colors.beige.c020};
      text-transform: uppercase;

      ${mediaQueries.md} {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }

      a {
        position: relative;
        font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: ${asRem(14)};
        font-weight: bold;
        letter-spacing: ${asRem(0.8)};

        ${mediaQueries.md} {
          &:not(:last-of-type) {
            margin-right: ${asRem(40)};
          }
        }
        
        &:after {
          left: 0;
          bottom: ${asRem(-2)};
          content: '';
          position: absolute;
          width: 100%;
          height: ${asRem(2)};
          background-color: ${colors.beige.c020};
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }
        
        
        &.active, &:hover {
          &:after {
            opacity: 1;
          }
        }
      }

      &.expanded {
        @media screen and (max-width: ${breakpoints.md}) {
          position: absolute;
          top: ${asRem(60)};
          left: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: rgba(31, 48, 72, 0.9);
          padding: ${asRem(40)};

          a {
            font-weight: 600;
            font-size: ${asRem(20)};

            &:not(:last-of-type) {
              margin-bottom: ${asRem(40)};
            }
          }
        }
        
      }
      
    }
  }
  
  .hamburger-button {
    padding: 0 ${asRem(15)};
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    outline: 0;
    overflow: visible;
    margin-left: auto;
    margin-bottom: -${asRem(6)};

    ${mediaQueries.md} {
      display: none;
    }

    .hamburger-inner {
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        transition: top 0.075s 0.12s ease,
        opacity 0.075s ease;
      }

      &::after {
        transition: bottom 0.075s 0.12s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before {
          top: 0;
          opacity: 0;
          transition: top 0.075s ease,
          opacity 0.075s 0.12s ease;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease,
          transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
      
    }
  }
  
`;

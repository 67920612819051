import styled from 'styled-components';
import { mediaQueries } from 'utils/Constants/Breakpoints';
import { colors } from 'utils/colors';
import { asRem } from 'utils/asRem';

export default styled.p `

  font-family: "Source Sans Pro";
  color: ${colors.deprecated.white.c500};
  font-weight: 300;
  font-size: ${asRem(18)};
  line-height: ${asRem(24)};
  letter-spacing: ${asRem(0.15)};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  align-self: center;
  margin: ${asRem(20)} 0 ${asRem(30)};


  ${mediaQueries.lg} {
    flex-basis: 65%;
  }
  
`;

import { navigate, RouteComponentProps } from '@reach/router';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  IbanElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import {
  StripeCardNumberElement,
  StripeCardNumberElementOptions,
  StripeIbanElement
} from '@stripe/stripe-js';
import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useState
} from 'react';
import { useStripeSetupIntentLazyQuery } from 'api/graphql';
import LoadingSpinner from 'components/LoadingSpinner';
import { ACTION_TYPES } from 'utils/typings/actionTypes';
import { ROUTES } from 'utils/typings/routes';
import { Store } from 'utils/store';
import { logError } from 'utils/logError';

const PaymentMethodForm: React.FC<RouteComponentProps> = () => {
  const elements = useElements();
  const stripe = useStripe();
  const [store, dispatch] = useContext(Store);
  const { billingDetails } = store;

  interface IState {
    stripeErrorMessage: String;
    paymentMethod: String;
    isProcessing: boolean;
  }

  const [state, setState] = useState<IState>({
    stripeErrorMessage: '',
    paymentMethod: 'creditCard',
    isProcessing: false
  });

  useEffect(() => {
    if (!billingDetails) {
      navigate(ROUTES.BILLING_DETAILS);
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [billingDetails]);

  const [
    getSetupIntent,
    {
      data: setupIntentData,
      loading: setupIntentLoading,
      error: setupIntentError,
      refetch: refetchSetupIntent
    }
  ] = useStripeSetupIntentLazyQuery({
    onCompleted: (data) => {
      getPaymentMethod(data.stripeSetupIntent.clientSecret);
    },
    onError: (error) => {
      logError(error);
    }
  });

  if (setupIntentError) {
    logError(setupIntentError);
  }

  const handleTogglePaymentMethod = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: paymentMethod } = event.target;
    setState((prevState) => ({
      ...prevState,
      paymentMethod,
      stripeErrorMessage: ''
    }));
  };

  const getPaymentMethod = async (clientSecret: String) => {
    if (!billingDetails || !stripe) {
      return;
    }

    const ibanElement = elements?.getElement('iban') as StripeIbanElement;
    const cardElement = elements?.getElement(
      'cardNumber'
    ) as StripeCardNumberElement;

    const { firstName, lastName, country, email, addressLine1, zipCode, city } =
      billingDetails;

    const billing_details = {
      name: `${firstName} ${lastName}`,
      email,
      address: {
        city,
        country,
        line1: addressLine1,
        postal_code: zipCode
      }
    };

    try {
      setState((prevState) => ({
        ...prevState,
        isProcessing: true
      }));
      const stripeConfirmSetup =
        state.paymentMethod === 'creditCard'
          ? await stripe.confirmCardSetup(`${clientSecret}`, {
              payment_method: {
                card: cardElement,
                billing_details
              }
            })
          : await stripe.confirmSepaDebitSetup(`${clientSecret}`, {
              payment_method: {
                sepa_debit: ibanElement,
                billing_details
              }
            });
      setState((prevState) => ({
        ...prevState,
        isProcessing: false
      }));
      if (stripeConfirmSetup?.error) {
        throw stripeConfirmSetup.error;
      } else if (stripeConfirmSetup?.setupIntent) {
        dispatch({
          type: ACTION_TYPES.UPDATE_STRIPE_SETUP_INTENT,
          stripeSetupIntent: stripeConfirmSetup.setupIntent
        });
        navigate(ROUTES.SUMMARY);
      }
    } catch (error: any) {
      isError(error) &&
        setState((prevState) => ({
          ...prevState,
          stripeErrorMessage: error.message
        }));
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    // onComplete won't fire on refetch, so if refetch is present, setupIntentData
    // will be as well and is updated on each refetch
    if (setupIntentData) {
      getPaymentMethod(setupIntentData.stripeSetupIntent.clientSecret);
    }

    if (refetchSetupIntent) {
      refetchSetupIntent();
    } else {
      getSetupIntent();
    }
  };

  return (
    <form
      className="payment-method__form"
      onSubmit={(event) => handleSubmit(event)}
    >
      <div className="payment-method__radio-wrapper">
        <input
          className="payment-method__input-radio"
          type="radio"
          id="radioCreditCard"
          name="radio-group"
          defaultChecked
          onChange={handleTogglePaymentMethod}
          value="creditCard"
        />
        <label
          className={`payment-method__label-radio ${
            state.paymentMethod === 'creditCard'
              ? 'payment-method__label-radio--active'
              : ''
          }`}
          htmlFor="radioCreditCard"
        >
          Kreditkarte
        </label>
        <input
          className="payment-method__input-radio"
          type="radio"
          id="radioSepa"
          name="radio-group"
          value="sepa"
          onChange={handleTogglePaymentMethod}
        />
        <label
          className={`payment-method__label-radio ${
            state.paymentMethod === 'sepa'
              ? 'payment-method__label-radio--active'
              : ''
          }`}
          htmlFor="radioSepa"
        >
          Sepa/Lastschrift
        </label>
      </div>
      {state.paymentMethod === 'sepa' && (
        <div className="payment-method__stripe-wrapper">
          <div className="StripeElement__wrapper">
            <label className="StripeElement__label" htmlFor="iban">
              IBAN
            </label>
            <IbanElement
              id="iban"
              options={{
                ...stripeInputStyle,
                supportedCountries: ['SEPA'],
                placeholderCountry: 'DE',
                iconStyle: 'solid'
              }}
            />
          </div>
          {state.stripeErrorMessage ? (
            <div className="payment-method__invalid-feedback">
              {state.stripeErrorMessage}
            </div>
          ) : null}
        </div>
      )}
      {state.paymentMethod === 'creditCard' && (
        <div className="payment-method__stripe-wrapper">
          <div className="StripeElement__wrapper">
            <label className="StripeElement__label" htmlFor="card-number">
              Kartennummer
            </label>
            <CardNumberElement
              id="card-number"
              options={{
                ...stripeInputStyle,
                showIcon: true,
                iconStyle: 'solid'
              }}
            />
          </div>
          <div className="StripeElement__wrapper">
            <label className="StripeElement__label" htmlFor="card-expiry">
              Ablaufdatum
            </label>
            <CardExpiryElement id="card-expiry" options={stripeInputStyle} />
          </div>
          <div className="StripeElement__wrapper">
            <label className="StripeElement__label" htmlFor="card-cvc">
              Prüfziffer
            </label>
            <CardCvcElement
              id="card-cvc"
              options={{ ...stripeInputStyle, placeholder: '123' }}
            />
          </div>
          {state.stripeErrorMessage ? (
            <div className="payment-method__invalid-feedback">
              {state.stripeErrorMessage}
            </div>
          ) : null}
        </div>
      )}
      <div className="payment-method__btn-wrapper">
        <button
          title="Zur letzten Seite"
          className="payment-method__btn--blue"
          onClick={() => window.history.back()}
          disabled={setupIntentLoading || state.isProcessing}
        >
          Zurück
        </button>
        <button
          className="payment-method__btn--orange"
          type="submit"
          disabled={setupIntentLoading || state.isProcessing}
          title="Weiter im Kaufprozess"
        >
          {setupIntentLoading || state.isProcessing ? (
            <LoadingSpinner />
          ) : (
            'Weiter'
          )}
        </button>
      </div>
    </form>
  );
};

export default PaymentMethodForm;

function isError(err: any | Error): err is Error {
  return typeof err.message === 'string';
}

const stripeInputStyle: StripeCardNumberElementOptions = {
  style: {
    base: {
      iconColor: '#b2b3ae',
      fontFamily:
        '"Source Sans Pro", "Helvetica Neue", Helvetica, Arial,sans-serif',
      fontSize: '18px',
      color: '#f3f0e5',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#f3f0e5'
      },
      '::placeholder': {
        color: '#b2b3ae'
      },
      ':focus': {
        backgroundColor: 'rgba(31, 48, 72, 0.9)'
      }
    },
    invalid: {
      iconColor: '#e06f4c',
      color: '#e06f4c'
    }
  }
};

import React, { FunctionComponent } from 'react';
import Style from './BgStars.style';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}
export const BgStars: FunctionComponent<IProps> = ({ children, className, style }) => {
  return (
    <Style className={className} style={style}>
      <div className="content-wrapper">
        {children}
      </div>
    </Style>
  );
};

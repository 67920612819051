import React, { FunctionComponent } from 'react';
import Style from './Paragraph.style';

interface IProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export const Paragraph: FunctionComponent<IProps> = ({ children, ...props }) => {
  return (
    <Style { ...props }>
      {children}
    </Style>
  );
};

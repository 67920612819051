export const _mockData = [
  {
    'name': 'actimonda Krankenkasse',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'AGIDA ',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'AOK Baden-Württemberg',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'AOK Bayern',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'AOK Bremen/Bremerhaven',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'AOK Hessen',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'AOK Niedersachsen',
    'reductionRate': 85,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'AOK Nordost',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'AOK Nordwest',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'AOK Plus',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'AOK Rheinland/Hamburg',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'AOK Rheinland-Pfalz/Saarland',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'AOK Sachsen Anhalt',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'atlas BKK ahlmann',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Audi BKK',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Bahn-BKK',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BARMER',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Bertelsmann BKK',
    'reductionRate': 85,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BIG direkt gesund',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK24',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK advita',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Aesculap',
    'reductionRate': 75,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK AKZO Nobel',
    'reductionRate': 90,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK B. Braun Melsungen AG',
    'reductionRate': 75,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK BPW Bergische Achsen KG',
    'reductionRate': 85,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Deutsche Bank',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Diakonie',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Dürkopp Adler',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK EUREGIO',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK evm',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK EWE',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK exklusiv',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Faber Castell \u0026 Partner',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Firmus',
    'reductionRate': 90,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Freudenberg',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK GILDEMEISTER SEIDENSTICKER',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Groz-Beckert',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK HENSCHEL Plus',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Herkules',
    'reductionRate': 95,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK HMR',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Karl Mayer',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK KBA',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Linde',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK MAHLE',
    'reductionRate': 85,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Melitta Plus',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK MEM',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Merck',
    'reductionRate': 85,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Miele',
    'reductionRate': 85,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Mobil Oil',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK MTU',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK PFAFF',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Pfalz',
    'reductionRate': 90,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK ProVita',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Public',
    'reductionRate': 85,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK pwc',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK RRW',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK RWE',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Salzgitter',
    'reductionRate': 85,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK SBH',
    'reductionRate': 85,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK SCHEUFELEN',
    'reductionRate': 85,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Schwarzwald-Baar-Heuberg',
    'reductionRate': 85,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Stadt Augsburg',
    'reductionRate': 75,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Technoform',
    'reductionRate': 90,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Textilgruppe Hof',
    'reductionRate': 90,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK VBU',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK VDN',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK VerbundPlus',
    'reductionRate': 90,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Vital',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK voralb',
    'reductionRate': 85,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK WERRA-MEISSNER',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Wirtschaft und Finanzen',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK Würth',
    'reductionRate': 90,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BKK ZF \u0026 Partner',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BMW BKK',
    'reductionRate': 90,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'BOSCH BKK',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Brandenburgische BKK',
    'reductionRate': 90,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Continentale BKK',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'DAK Gesundheit',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Daimler BKK',
    'reductionRate': 90,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Debeka BKK',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Die BERGISCHE Krankenkasse',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'energie BKK',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'EY BKK',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Freudenberg BKK',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'HEIMAT',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'HEK',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'HKK',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'IKK BB',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'IKK classic',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'IKK - Die Innovationskasse',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'IKK gesundplus',
    'reductionRate': 90,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'IKK Südwest',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'KKH',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Knappschaft',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Krones BKK',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Landwirtschaftliche Krankenkasse (LKK)',
    'reductionRate': 90,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Merck BKK',
    'reductionRate': 85,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'mhplus',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Novitas BKK',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'pronova',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'R+V BKK',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Salus BKK',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'SBK Siemens-Betriebskrankenkasse',
    'reductionRate': 75,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Schwenninger Krankenkasse',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'SECURVITA',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'SIEMAG BKK',
    'reductionRate': 85,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'SKD BKK',
    'reductionRate': 90,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Sozialvers. für Landwirtschaft, Forsten \u0026 Gartenbau',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Südzucker BKK',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'TBK BKK',
    'reductionRate': 90,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'TK | Techniker Krankenkasse',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'TUI BKK',
    'reductionRate': 85,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'VIACTIV',
    'reductionRate': 100,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Vivida BKK',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'Wieland BKK',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  },
  {
    'name': 'WMF BKK',
    'reductionRate': 80,
    'cooperationUrl': null,
    'cooperationPartner': false
  }
];

import { RouteComponentProps } from '@reach/router';
import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useState
} from 'react';
import { OffersQueryVariables } from 'api/graphql';
import Header from 'components/Header';
import LoadingSpinner from 'components/LoadingSpinner';
import { ACTION_TYPES } from 'utils/typings/actionTypes';
import { ROUTES } from 'utils/typings/routes';
import { Store } from 'utils/store';
import URLParam from 'utils/urlParameter';
import Footer from 'views/Footer/Footer';
import { ParamType } from 'utils/typings/urlParamType';
import { logError } from 'utils/logError';
import { Button, Caption, Paragraph } from 'View/Common';
import Style from './Pricing.style';
import { ZppOffers } from './ZppOffers/ZppOffers';
import DeprecatedOffers from './DeprecatedOffers/DeprecatedOffers';
import { useGetOffers } from 'View/HookApi/useGetOffers';

const Pricing: React.FC<RouteComponentProps> = (props) => {
  const { path } = props;

  const [_, dispatch] = useContext(Store);

  const { loading, error, refetch, offers, couponCode: enteredCouponCode } = useGetOffers();

  const [state, setState] = useState({
    couponInvalid: false,
    couponValid: false
  });

  useEffect(() => {
    dispatch({
      type: ACTION_TYPES.CLEAN_UP_SELECTED_OFFER
    });
    dispatch({
      type: ACTION_TYPES.CLEAN_UP_ENTEREDCODE
    });
  }, [dispatch]);

  const [couponCode, setCouponCode] = useState('');

  const updateCouponCode = (code: string) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_ENTERED_COUPON_CODE,
      enteredCouponCode: code
    });

    refetch({ couponCode: code } as OffersQueryVariables).catch(console.error);
  };

  useEffect(() => {
    if (URLParam(window.location, ParamType.CODE)) {
      const code = URLParam(window.location, ParamType.CODE);
      updateCouponCode(code);
    }
  }, [dispatch, refetch]);

  if (error) {
    logError(error);
  }

  const handleSubmitCoupon = (event: FormEvent) => {
    event.preventDefault();
    updateCouponCode(couponCode);
  };

  const handleChangeCouponCode = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value } = event.target;
    setCouponCode(value);
  };

  useEffect(() => {
    setCouponCode(enteredCouponCode);
    if(!enteredCouponCode.length || !offers.zpp || !offers.subscriptions.length) {
      setState({ couponValid: false, couponInvalid: false });
      return;
    }

    const isCouponValid = [ offers.zpp, ...offers.subscriptions ].some((offer) => !!offer?.crossedOutPrice);
    setState({ couponValid: isCouponValid, couponInvalid: !isCouponValid });
  }, [offers, enteredCouponCode]);

  const { couponValid, couponInvalid } = state;

  return (
    <>
      <Header path={path} />
      <Style className="main main--no-footer-cta pricing">
        <div className="wrapper flex-column padding-top-100 margin-bottom-50">
          <h1 className="heading heading-1 margin-bottom-8">
            7Schläfer im Abo
          </h1>
          <Paragraph>
            Erhalte Zugriff auf alle 7Schläfer Kapitel sowie die stetig
            wachsende Bibliothek der Einschlafhilfen.
          </Paragraph>
          {loading || !offers.subscriptions.length ? (
            <LoadingSpinner />
          ) : (
            <>
              { process.env.REACT_APP_ZPP_APP === '1' ?
                <ZppOffers offers={offers} /> :
                <DeprecatedOffers offers={offers} />
              }

              <div className="wrapper__offers flex-column">
                {couponValid ? (
                  <div className="success-feedback">
                    Rabatt erfolgreich eingelöst
                  </div>
                ) : (
                  <form onSubmit={handleSubmitCoupon}>
                    <div className="coupon_code">
                      <div className="wrapper__input">
                        <input
                          placeholder="Rabatt coupon"
                          type="text"
                          onChange={handleChangeCouponCode}
                          value={couponCode || ''}
                          id="coupon_code"
                          className="coupon_field"
                          required
                          autoCorrect="off"
                          autoCapitalize="off"
                          autoComplete="off"
                        />
                        <button
                          type="submit"
                          className="payment-method__btn--blue coupon_button"
                          title="Rabattcode einlösen"
                        >
                          {enteredCouponCode && !couponInvalid ? (
                            <LoadingSpinner />
                          ) : (
                            'Rabatt einlösen'
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                )}

                {couponInvalid && (
                  <div className="invalid-feedback">Coupon-Code ungültig</div>
                )}
              </div>
              <Caption small className="text-center">
                *Du kannst das Abo jederzeit zum Ende der Abo-Laufzeit kündigen.
                Ansonsten verlängert sich das Abo automatisch um die Dauer des
                jeweiligen Abo-Zeitraums und kann jederzeit mit einer Frist von
                einem Monat gekündigt werden. Die Bezahlung erfolgt vorab für
                den jeweiligen Abo-Zeitraum. Sofern das Abo vor Ablauf des
                jeweiligen Abo-Zeitraums wirksam durch Kündigung beendet wird,
                wird Dir die Bezahlung anteilig erstattet. Erfordert Android 8.0
                bzw. iOS 12.0 oder höher.
              </Caption>
            </>
          )}
        </div>
        <Button type="link" title="einen neuen Freischalt-Code anfordern" to={ROUTES.RESEND_TOKEN}>Neuen Freischalt-Link anfordern</Button>
        <br /><br /><br />
      </Style>
      <Footer />
    </>
  );
};

export default Pricing;

import React, { FunctionComponent } from 'react';
import { Modal } from 'View/Component';
import { StaticPageName, useStaticPagesQuery } from 'api/graphql';
import MarkdownRenderer from 'components/MarkdownRenderer';
import { Bold } from 'View/Common/Bold/Bold';

interface IProps {
  open?: boolean;
  onClose?: () => void;
  pageName: StaticPageName;
}

export const MarkdownModal: FunctionComponent<IProps> = ({ open, onClose, pageName }) => {
  const { data, loading, error } = useStaticPagesQuery({
    variables: { name: pageName }
  });

  const markdownData = data?.staticPages?.[0]?.markdown;

  return (
    <Modal onClose={onClose} open={open}>
      {markdownData && <MarkdownRenderer data={markdownData} inModal />}
      {loading && <Bold className="paragraph ta-left">Loading...</Bold>}
      {error && <Bold className="paragraph ta-left">Fehler beim Laden der Seite. Bitte versuchen Sie es später erneut.</Bold>}
    </Modal>
  );
};

import React, { FunctionComponent } from 'react';
import Style from './Menu.style';
import { MenuEntries } from 'utils/Constants/MenuEntries';
import { Link, useLocation } from '@reach/router';
import { ROUTES } from 'utils/typings/routes';

interface IProps {
  noNavigation?: boolean;
}
export const Menu: FunctionComponent<IProps> = ({ noNavigation }) => {

  const [ expanded, setExpanded ] = React.useState(false);
  const { pathname } = useLocation();

  return (
    <Style>
      <div className="wrapper">
        <Link className="link-logo" to={ROUTES.HOME} title="Gehe zur Startseite">
          <div className="image-logo" />
        </Link>
        { !noNavigation && (
          <>
            <nav className={`menu ${expanded ? 'expanded' : ''}`}>
              { MenuEntries.map(({ to, title }, index) => (
                <Link className={ to === pathname ? 'active' : '' } key={`menuEntry${index}`} title={title} to={to}>{title}</Link>
              ))}
            </nav>
            <button
              className={`hamburger-button ${expanded ? 'is-active' : ''}`}
              aria-expanded={expanded}
              onClick={() => setExpanded(!expanded)}
              title="öffnet das Navigations-Menü"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </>
        )}
      </div>
    </Style>
  );

};

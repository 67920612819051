import { ROUTES } from 'utils/typings/routes';

export type TMenuEntry = { to: string, title: string };

export const MenuEntries: TMenuEntry[] = [
  { to: ROUTES.HOME, title: 'Home' },
  { to: ROUTES.PRICING, title: 'Preise' },
  { to: ROUTES.CONTACT, title: 'Kontakt' }
];

if(process.env.REACT_APP_ZPP_APP === '1') {
  MenuEntries.splice(1, 0, { to: ROUTES.ZPP_LANDING_PAGE, title: 'Krankenkasse' });
}

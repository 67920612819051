import styled from 'styled-components';

export default styled.div `

  img {
    width: 100%;
    height: auto;
  }
  
`;

import React, { FunctionComponent } from 'react';
import { HealthInsuranceSelect, IHealthInsuranceEntry } from 'View/Component';
import { Bold, Button, Card, Col, Headline, HR, Paragraph, Row } from 'View/Common';
import { useGetOffers } from '../../HookApi/useGetOffers';


interface IProps {
  healthInsurance?: IHealthInsuranceEntry|null;
  onChange?: (healthInsurance: IHealthInsuranceEntry|null) => void;
  onReset?: () => void;
}
export const HealthInsuranceSelectionBox: FunctionComponent<IProps> = ({ healthInsurance, onChange }) => {

  const { offers } = useGetOffers();
  const zppOffer = offers.zpp;

  const handleOnSetHealthInsurance = (healthInsurance: IHealthInsuranceEntry) => {
    onChange && onChange(healthInsurance);
  };

  const handleOnResetHealthInsurance = () => {
    onChange && onChange(null);
  };

  const getReduction = (reductionRate: number | undefined) => {
    const price = zppOffer?.price?.cents || 0;
    if (reductionRate) {
      return (price * reductionRate / 10000).toFixed(2);
    }
    return '0,00';
  };

  const getPayablePart = (reductionRate: number | undefined) => {
    const price = zppOffer?.price?.cents || 0;

    if (reductionRate) {
      return ((price - (price * (reductionRate / 100))) / 100).toFixed(2);
    }
    return (price / 100).toFixed(2);
  };

  if(!healthInsurance) {
    return (
      <>
        <Headline level={2}>Mach jetzt den Erstattungs-Check:</Headline>
        <Paragraph>
          Sichere dir den Präventionskurs "Erholsamen Schlaf finden – ein App-gestützter Online-Kurs"
          und 12 Monate Zugang zur gesamten 7Schläfer App.
        </Paragraph>
        <Paragraph>
          Wie hoch ist deine Erstattung?
        </Paragraph>
        <HealthInsuranceSelect onChange={handleOnSetHealthInsurance}/>
      </>
    );
  }

  return (
    <>
      <Headline level={2}>Wie schön!</Headline>
      <Paragraph>
        <Bold>
          {healthInsurance?.name} Versicherte bekommen {healthInsurance?.reductionRate}% der Kosten für dieses Angebot
          erstattet. Achte darauf, den Kurs in 6 Monaten abzuschließen. <br />
        </Bold>
        Kein Abo, keine Kündigung notwendig.
      </Paragraph>
      <Button type="link" title="Krankenkasse ändern" onClick={handleOnResetHealthInsurance}>
        Krankenkasse ändern
      </Button>
      <br /><br />
      <Card title={`Erstattung ${healthInsurance?.reductionRate} %`}>
        <Row className="regular-font">
          <Col span={20}>
            <Paragraph>Preis für zwölf Monate (Vorleistung)</Paragraph>
          </Col>
          <Col span={4} className="text-right">
            <Paragraph>{ zppOffer?.price?.localizedPrice }</Paragraph>
          </Col>
          <Col span={20}>
            <Paragraph>Erstattung {healthInsurance?.reductionRate}% (Nach Kurs-Absolvierung)</Paragraph>
          </Col>
          <Col span={4} className="text-right">
            <Paragraph>- {getReduction(healthInsurance?.reductionRate)} €</Paragraph>
          </Col>
        </Row>
        <HR />
        <Row>
          <Col span={20}>
            <Paragraph><Bold>Dein Anteil</Bold></Paragraph>
          </Col>
          <Col span={4} className="text-right">
            <Paragraph><Bold>{getPayablePart(healthInsurance?.reductionRate)} €</Bold></Paragraph>
          </Col>
        </Row>
      </Card>
    </>
  );

};

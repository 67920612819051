import React from 'react';

const LoadingSpinner = React.memo(() => {
  return (
    <div className="loading-spinner__wrapper">
      <div className="loading-spinner" role="status">
        <span className="visuallyhidden">Lädt</span>
      </div>
    </div>
  );
});

export default LoadingSpinner;

import React, { FunctionComponent } from 'react';
import Style from './Image.style';
import { ImageProps } from 'antd';

interface IProps {
  image: string;
  srcSet?: Record<string, string>;
  alt?: string;
  title?: string;
  style?: React.CSSProperties;
  className?: string;
}

export const Image: FunctionComponent<IProps> = ({ image, srcSet, alt, title, style, className }) => {

  const imageProps: Pick<ImageProps, 'src' | 'alt' | 'title' | 'style' | 'srcSet'> = {
    src: image,
    alt,
    title,
    style
  };

  if(srcSet) {
    imageProps.srcSet = Object.keys(srcSet).map((key) => `${srcSet[key]} ${key}`).join(', ');
  }

  return (
    <Style className={className}>
      <img { ...imageProps } />
    </Style>
  );
};
